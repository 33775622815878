import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { navigate } from "gatsby";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";

export default function SearchBar() {
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    if (!search) return;
    navigate(`/shop?search=${search}`);
    setSearch("");
  };

  return (
    <Form className="d-flex" onSubmit={handleSearch}>
      <Form.Group controlId="searchBar" style={{ width: "100%" }}>
        <Form.Control
          size="lg"
          type="text"
          className="ml-1"
          placeholder="Search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </Form.Group>
      <Button size="lg" variant="search" type="submit">
        <FaSearch />
      </Button>
    </Form>
  );
}
