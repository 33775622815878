import React from "react";
import { AiFillCaretRight } from "react-icons/ai";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";

import styles from "../styles/components/viewNowBtn.module.scss";

const ViewNowBtn = ({ primary, secondary, link, text = "View Now" }) => {
  return (
    <Link to={link}>
      <Button
        className={
          primary
            ? `${styles.btn} ${styles.primary}  ${styles.text} m-auto p-4 m-lg-0 h2`
            : secondary
            ? `${styles.btn} ${styles.secondary}  ${styles.text} m-auto p-4 m-lg-0 h2`
            : `${styles.btn}  ${styles.text} m-auto  p-4 m-lg-0 h2`
        }
      >
        {text}
        <AiFillCaretRight />
      </Button>
    </Link>
  );
};

export default ViewNowBtn;
