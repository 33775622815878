import axios from "axios";
import { toast } from "react-toastify";

export const getProducts = () => {
  return async (dispatch, getState) => {
    // If products have already been fetched, return
    // if (
    //   getState().products.products &&
    //   getState().products.products.length > 0
    // ) {
    //   return;
    // }
    try {
      const ecommerceData = await axios.get(
        `${process.env.GATSBY_API_CALL}/products`
      );

      dispatch(setProducts(ecommerceData.data));
    } catch (e) {
      toast.error(`Error. ${e.response.data.message}`);
    }
  };
};

const setProducts = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_PRODUCTS",
      data: data,
    });
  };
};

export const editPrice = (data) => {
  return (dispatch) => {
    dispatch({
      type: "EDIT_PRICE",
      data: data,
    });
  };
};

export const editStock = (data) => {
  return (dispatch) => {
    dispatch({
      type: "EDIT_STOCK",
      data: data,
    });
  };
};
