import React from "react";
import Layout from "../components/layout";
import SupplementsPg from "../components/supplements/supplementsPg";
import SEO from "../components/seo";
const Supplements = ({ location }) => {
  return (
    <Layout>
      <SEO title="Shop" article={false} pathname={`/shop`} />

      <SupplementsPg location={location} />
    </Layout>
  );
};

export default Supplements;
