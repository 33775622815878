import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, Link, navigate } from "gatsby";
import { connect } from "react-redux";
import _ from "lodash";
import { Accordion, OverlayTrigger, Tooltip, Container } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import { getProducts } from "../../state/actions/products";
import SearchBar from "../searchBar";
import ViewNowBtn from "../viewNowBtn";
import useWindowDimensions from "../../utils/useWindowDimensions";
import convertNumber from "../../utils/convertNumber";

import styles from "../../styles/components/supplements/supplementsPg.module.scss";

const SupplementsPg = ({ getProducts, products, location }) => {
  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    hydrateProducts();
  }, [products]);

  const { width } = useWindowDimensions();
  const [filter, setFilter] = useState("");

  const data = useStaticQuery(graphql`
    query {
      allContentfulSupplement {
        edges {
          node {
            contentful_id
            slug
            name
            flavor
            category
            productImages {
              file {
                url
              }
            }
            otherFlavors {
              flavor
              slug
              flavorIcon {
                file {
                  url
                }
                fixed {
                  src
                }
              }
            }
            flavorIcon {
              file {
                url
              }
              fixed {
                src
              }
            }
          }
        }
      }
    }
  `);

  const [productList, setProductList] = useState(
    data.allContentfulSupplement.edges.map((edge) => {
      return {
        id: edge.node.contentful_id,
        slug: edge.node.slug,
        name: edge.node.name,
        flavor: edge.node.flavor,
        category: edge.node.category,
        image: edge.node.productImages[0].file.url,
        otherFlavors: [
          ...(edge.node.otherFlavors ?? []),
          edge.node.flavor && {
            flavor: edge.node.flavor,
            flavorIcon: edge.node.flavorIcon,
            slug: edge.node.slug,
          },
        ],
      };
    })
  );

  const hydrateProducts = () => {
    if (products.length !== 0) {
      const newProductArr = productList.map((product) => {
        const details = products.find((item) => item.productID === product.id);
        return { ...product, price: details.price, stock: details.stock };
      });

      setProductList(newProductArr);
    }
  };

  // Available Categories
  const categories = [
    "All",
    "Protein",
    "Pre-Workout",
    "Mass-Gainer",
    "Fish Oil, Omega 3, Multi Vitamin",
    "BCAA, Amino & Glutamine",
    "Creatine",
    "Testosterone Booster",
    "Fat Burner",
  ];

  const setCategory = (cat) => {
    navigate(`/shop?category=${cat.replace(/&/g, "%26")}`);
  };

  // Search Params
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // Filter products using query params(categories, search terms), and state (filters)
  const filteredProducts = productList
    .filter(
      (product) =>
        _.isEmpty(params) ||
        product.name.toLowerCase().includes(params.search?.toLowerCase()) ||
        product.flavor?.toLowerCase().includes(params.search?.toLowerCase()) ||
        product.category.toLowerCase().includes(params.search?.toLowerCase()) ||
        params.category === "All" ||
        product.category === params.category
    )
    .sort((a, b) => {
      // sort by stock
      if (a.stock > b.stock) {
        return -1;
      }
    })
    .filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)
    .sort((a, b) => {
      if (filter === "price:lth" && b.price > a.price) {
        return -1;
      } else if (filter === "price:htl" && a.price > b.price) {
        return -1;
      }
    });

  const flavorTooltip = ({ flavor }) => {
    return (
      <Tooltip id="tooltip">
        <strong>{flavor}</strong>
      </Tooltip>
    );
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <div onClick={width < 768 ? decoratedOnClick : null}>{children}</div>
    );
  }

  return (
    <div className={styles.ProductsPg}>
      {/* Product Filter */}
      <div className={`${styles.filterBar} p-5`}>
        <h1 className={`${styles.titleMain} h1 mb-4 mt-5`}>Products</h1>
        <div>
          <div className="mb-3">
            <SearchBar />
          </div>
          <Accordion defaultActiveKey={width > 768 ? "0" : ""}>
            <CustomToggle eventKey="0">
              <Accordion.Item eventKey="0" className={styles.filterWrapper}>
                <Accordion.Header className={`${styles.category}`}>
                  Category
                </Accordion.Header>

                <Accordion.Body>
                  {categories.map((cat) => (
                    <h6
                      className={
                        params.category === cat
                          ? `${styles.categoryItem} ${styles.active} h5`
                          : `${styles.categoryItem} h5`
                      }
                      onClick={() => setCategory(cat)}
                      key={cat}
                    >
                      {cat}
                    </h6>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </CustomToggle>
          </Accordion>
          <Accordion defaultActiveKey={width > 768 ? "0" : ""}>
            <CustomToggle eventKey="0">
              <Accordion.Item eventKey="0" className={styles.filterWrapper}>
                <Accordion.Header className={`${styles.category}`}>
                  Sort By
                </Accordion.Header>
                <Accordion.Body>
                  <h6
                    className={
                      filter === "price:lth"
                        ? `${styles.categoryItem} ${styles.active} h5`
                        : `${styles.categoryItem} h5`
                    }
                    onClick={() => setFilter("price:lth")}
                  >
                    Price: Low to High
                  </h6>
                  <h6
                    className={
                      filter === "price:htl"
                        ? `${styles.categoryItem} ${styles.active} h5`
                        : `${styles.categoryItem} h5`
                    }
                    onClick={() => setFilter("price:htl")}
                  >
                    Price: High to Low
                  </h6>
                </Accordion.Body>
              </Accordion.Item>
            </CustomToggle>
          </Accordion>
        </div>
      </div>

      {/* Item List */}
      <div>
        {/* Messages */}
        {params.search && filteredProducts.length > 0 && (
          <p className="px-5 pt-lg-5">
            Found {filteredProducts.length} results for{" "}
            <strong>"{params.search}"</strong>
          </p>
        )}
        {params.category &&
          params.category !== "All" &&
          filteredProducts.length > 0 && (
            <p className="px-5 pt-lg-5">
              Showing results for
              <strong> "{params.category}"</strong>
            </p>
          )}
        {!filteredProducts.length && (
          <p className="px-5 pt-lg-5 ">
            Sorry, there are currently no products{" "}
            {params.search && (
              <span>
                that match <strong>"{params.search}".</strong>
              </span>
            )}
            {params.category && "for this category."} Try again soon!
          </p>
        )}

        <div className={`${styles.productList} p-5`}>
          {filteredProducts.map((product) => (
            <div key={product.id}>
              <div className={styles.product}>
                <Link to={`/shop/${product.slug}`} className="text-center">
                  <img
                    src={product.image}
                    alt={product.name}
                    style={{ width: "100%" }}
                  />
                  <h2 className={`${styles.itemName} h2 mb-2`}>
                    {product.name}
                  </h2>
                  {!!product.price && (
                    <h4 className={`${styles.itemPrice} h3 mb-2`}>
                      LKR {convertNumber(product.price)}
                    </h4>
                  )}
                </Link>
                <div className={`${styles.productFlavors} mb-3`}>
                  {product.otherFlavors.map((flavor) => {
                    return (
                      flavor?.flavorIcon && (
                        <OverlayTrigger
                          key={flavor.flavor}
                          placement="top"
                          overlay={flavorTooltip(flavor)}
                        >
                          <Link to={`/shop/${flavor.slug}`}>
                            <img src={flavor.flavorIcon.fixed.src} />
                          </Link>
                        </OverlayTrigger>
                      )
                    );
                  })}
                </div>
                <ViewNowBtn link={`/shop/${product.slug}`} secondary />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => dispatch(getProducts()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplementsPg);
